import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { getMonthRange } from "../../utils/mcdUtils";
import { FormControl, TextField } from "@mui/material";
import Rating from "@mui/material/Rating";
import {
  TdsButton,
  TdsProgressCircle,
  TdsTextInput,
  TdsTypography,
} from "@tds/react";
import Footer from "../Footer";
import logo from "../../images/ThalesLogo.png";
import pusleTrackerLogo from "../../images/pulseTracker.svg";
import { hostName } from "../api";
import { adalApiFetch } from "../../adal/adalConfig";
import useLayoutType from "../../hooks/useLayoutType";

function SecondPage({
  payload,
  setPayload,
  setStep,
  apiKey,
  projectName,
  setProjectName,
}) {
  var CryptoJS = require("crypto-js");
  const { i18n } = useTranslation();
  const year = new Date().getFullYear();
  const DATE_TIME_FORMAT = "yyyy-MM-DDTHH:mm:ss";

  const [commentMood, setCommentMood] = useState(payload.moodComment);
  const [countMood, setCountMood] = useState(
    payload.moodComment ? payload.moodComment.length : 0
  );
  const [moodRating, setMoodRating] = useState(0 || payload.moodSatisfaction);

  const [commentConfidence, setCommentConfidence] = useState(
    payload.confindenceToDeliverComment
  );
  const [countConfidence, setCountConfidence] = useState(
    payload.confindenceToDeliverComment
      ? payload.confindenceToDeliverComment.length
      : 0
  );
  const [confidenceRating, setConfidenceRating] = useState(
    0 || payload.confindenceToDeliverSatisfaction
  );

  const [randomKey, setRandomKey] = useState("");
  const [encodedKey, setEncodedKey] = useState("");
  const layoutType = useLayoutType();
  // Payload string that have to be encoded
  const payloadString = JSON.stringify({
    ...payload,
    country: payload.country,
    gbuCode: payload.gbuCode,
    blCode: payload.blCode,
    ccName: payload.ccName,
    pName: payload.pName,
    moodComment: payload.moodComment,
    confindenceToDeliverComment: payload.confindenceToDeliverComment,
    language: payload.language,
    startDate: payload.startDate,
    endDate: moment(Date.now()).format(DATE_TIME_FORMAT),
  });

  useEffect(() => {
    let result = "";
    const publicKey =
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJ71fznR1SccBZzeO7Eq4FpV5tKgJkQetWyhkXZ1QzOlvAkpmYa0q0GXFfj41tyQd2aLl9kWBJw6PmehdEFj0lY9U1DRx0mNDEfewQgk/yaEPVxe5Or25+7bfgv/0Jr045udYSuOfkCr/14ImEk45banplGGIS1xq4dQ2mNWVwCwIDAQAB";

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setRandomKey(result);

    (async () => {
      try {
        const publicKeyData = Uint8Array.from(atob(publicKey), (c) =>
          c.charCodeAt(0)
        );
        const dataBuffer = new TextEncoder().encode(result);

        const importedKey = await crypto.subtle.importKey(
          "spki",
          publicKeyData,
          { name: "RSA-OAEP", hash: "SHA-256" },
          false,
          ["encrypt"]
        );

        const encryptedArrayBuffer = await crypto.subtle.encrypt(
          { name: "RSA-OAEP" },
          importedKey,
          dataBuffer
        );

        const encryptedString = btoa(
          String.fromCharCode(...new Uint8Array(encryptedArrayBuffer))
        );
        setEncodedKey(encryptedString);
      } catch (error) {
        console.error("Encryption Error:", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (moodRating) {
      setPayload((prev) => ({
        ...prev,
        moodSatisfaction: moodRating,
      }));
    }
  }, [moodRating]);

  useEffect(() => {
    if (confidenceRating) {
      setPayload((prev) => ({
        ...prev,
        confindenceToDeliverSatisfaction: confidenceRating,
      }));
    }
  }, [confidenceRating]);

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      moodComment: commentMood,
    }));
  }, [commentMood]);

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      confindenceToDeliverComment: commentConfidence,
    }));
  }, [commentConfidence]);

  function payloadEncription(payload) {
    return encrypt(payload, randomKey);
  }

  function encrypt(obj, key) {
    var keyEnc = CryptoJS.enc.Base64.parse(key);
    var objEnc = CryptoJS.enc.Utf8.parse(obj);
    var encrypted = CryptoJS.AES.encrypt(objEnc, keyEnc, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  const handleClickPrevious = () => {
    setStep((step) => {
      return step - 1;
    });
  };

  const handleClickSubmit = async () => {
    setStep((step) => {
      return step + 1;
    });

    setPayload((prev) => ({
      ...prev,
      endDate: moment(Date.now()).format(DATE_TIME_FORMAT),
    }));

    try {
      var requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          survey: payloadEncription(payloadString),
          key: encodedKey,
        }),
      };

      adalApiFetch(
        fetch,
        `${hostName}/api/v1/mcd/app/createSurvey`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleChangeMood = (value) => {
    setCommentMood(value.target.value);
    setCountMood(value.target.value.length);
  };

  const handleChangeConfidence = (value) => {
    setCommentConfidence(value.target.value);
    setCountConfidence(value.target.value.length);
  };
  const handleChangeProjectName = (value) => {
    setProjectName(value.target.value);
    setPayload((prev) => ({
      ...prev,
      pName: value.target.value,
    }));
  };
  return (
    <>
      <div className="mdc-header2">
        <img className="logo" src={logo} />
        <TdsTypography classes="title">
          <p>
            {getMonthRange(i18n.language)} {year}
          </p>
        </TdsTypography>
        <TdsTypography fontSize={5} classes="title mb-xs">
          <Trans i18nKey="title"></Trans>
        </TdsTypography>
      </div>
      <div>
        <div className="pulse">
        <div className="second-page-title">
          <TdsTypography fontSize={4}>
            <Trans i18nKey="pName"></Trans>
          </TdsTypography>
        </div>
        {
        layoutType === "desktop" &&
        <img className="logo-pulse" src={pusleTrackerLogo} />
      }
        </div>
        <div className="questions">
          <div className="right">
            <TdsTypography fontSize="6" classes="question text-bluegrey-600">
              <p>
                <Trans i18nKey="q5"></Trans>
              </p>
            </TdsTypography>
            <TdsTextInput
              autofocus
              autocomplete="off"
              sx={{
                placeholder: {
                  color: "red",
                },
              }}
              inputId={`project-name`}
              classes={`mt-xs mb-xs ${
                layoutType === "mobile" ? "project-mobile" : "project"
              }`}
              placeholder={`${
                i18n.language === "en"
                  ? "Enter your answer"
                  : "Entrez votre réponse"
              }`}
              size="medium"
              value={projectName}
              onValueChanged={handleChangeProjectName}
            />
          </div>

     
          <div className="page-subtitle">
        <TdsTypography fontSize={4}>
          <Trans i18nKey="q5Title"></Trans>
        </TdsTypography>
      </div>

          <TdsTypography classes="required-question mb-xs text-bluegrey-600">
            <Trans i18nKey="q5Body"></Trans>
          </TdsTypography>
          <div className="review">
            <TdsTypography fontSize={8} classes="mr-m text-bluegrey-600">
              <Trans i18nKey="notSatisfied"></Trans>
            </TdsTypography>
            <div className="rate">
              <Rating
                IconContainerComponent={(props) => {
                  const { value, ...other } = props;
                  return <label id={`mood-${value}`} {...other}></label>;
                }}
                id="mood-stars"
                size="large"
                name="simple-controlled"
                value={moodRating}
                onChange={(event, newValue) => {
                  setMoodRating(newValue);
                }}
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "#242a75",
                  },
                  "& .MuiRating-iconHover": {
                    color: "#242a75",
                  },
                }}
              />
            </div>
            <TdsTypography fontSize={8} classes="ml-m text-bluegrey-600">
              <Trans i18nKey="satisfied"></Trans>
            </TdsTypography>
          </div>

          <TdsTypography classes="text-bluegrey-600">
            <p>
              <Trans i18nKey="q6Body"></Trans>
            </p>
          </TdsTypography>
          <TdsTypography
            fontSize={7}
            classes="text text-italic text-bluegrey-600 mb-xs"
          >
            <Trans i18nKey="confidential"></Trans>
          </TdsTypography>

          <div className="text-box">
            <div className= "text-area-box">
              <FormControl>
                <TextField
                  id="mood-input-text"
                  multiline
                  placeholder={`${
                    i18n.language === "en"
                      ? "Enter your answer"
                      : "Entrez votre réponse"
                  }`}
                  value={commentMood}
                  onChange={handleChangeMood}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </div>
            <TdsTypography classes="text-bluegrey-300 characters">
              {500 - countMood}{" "}
              {i18n.language === "en" ? "characters left" : "caractères reste"}
            </TdsTypography>
          </div>

          <TdsTypography classes="mt-xxs mb-xs text-bluegrey-600 required-question">
            <Trans i18nKey="q7Body"></Trans>
          </TdsTypography>

          <div className="review">
            <TdsTypography fontSize={8} classes="mr-m text-bluegrey-600">
              <Trans i18nKey="notSatisfied"></Trans>
            </TdsTypography>
            <div className="rate">
              <Rating
                id="confidence-stars"
                size="large"
                name="simple-controlled"
                value={confidenceRating}
                IconContainerComponent={(props) => {
                  const { value, ...other } = props;
                  return <label id={`confidence-${value}`} {...other}></label>;
                }}
                onChange={(event, newValue) => {
                  setConfidenceRating(newValue);
                }}
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "#242a75",
                  },
                  "& .MuiRating-iconHover": {
                    color: "#242a75",
                  },
                }}
              />
            </div>
            <TdsTypography fontSize={8} classes="ml-m text-bluegrey-600">
              <Trans i18nKey="satisfied"></Trans>
            </TdsTypography>
          </div>

          <TdsTypography classes="text-bluegrey-600">
            <Trans i18nKey="q8Body"></Trans>
          </TdsTypography>
          <TdsTypography
            fontSize={7}
            classes="text text-italic text-bluegrey-600 mb-xs"
          >
            <Trans i18nKey="confidential"></Trans>
          </TdsTypography>
          <div className="text-box">
          <div className= "text-area-box">
              <FormControl>
                <TextField
                  id="mood-input-text"
                  multiline
                  placeholder={`${
                    i18n.language === "en"
                      ? "Enter your answer"
                      : "Entrez votre réponse"
                  }`}
                  value={commentConfidence}
                  onChange={handleChangeConfidence}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </div>
            <TdsTypography classes="text-bluegrey-300 characters">
              {500 - countConfidence}{" "}
              {i18n.language === "en" ? "characters left" : "caractères reste"}
            </TdsTypography>
          </div>
          <TdsTypography fontSize={8} classes="required text-bluegrey-600">
            <Trans i18nKey="required"></Trans>
          </TdsTypography>
        </div>
      </div>
      <div className="buttons">
        <TdsButton
          variant="outline"
          color="primary"
          classes="btn-previous"
          onClick={handleClickPrevious}
        >
          <Trans i18nKey="previous"></Trans>
        </TdsButton>
        <TdsButton
          variant="filled"
          color="primary"
          classes="btn-submit"
          onClick={handleClickSubmit}
          disabled={!(confidenceRating && moodRating)}
        >
          <Trans i18nKey="submit"></Trans>
        </TdsButton>
      </div>
      <Footer />
    </>
  );
}

export default SecondPage;
