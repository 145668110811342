import React from "react";
import { TdsButton, TdsIcon, TdsTypography } from "@tds/react";
import logo from "../images/ThalesLogo.png";
import { Trans, useTranslation } from "react-i18next";
import { getMonthRange } from "../utils/mcdUtils";
import { changeLanguage } from "i18next";

export default function Header() {
  const { i18n } = useTranslation();
  const year = new Date().getFullYear()

  return (
    <div className="mdc-header">
      <div className="language-buttons">
        <TdsIcon classes="text-white-100" icon="language"></TdsIcon>
        <TdsButton id="btnEn" classes="en" onClick={() => changeLanguage("en")}>EN</TdsButton>/
        <TdsButton id="btnFr" classes="fr" onClick={() => changeLanguage("fr")}>FR</TdsButton>
      </div>

      <img className="logo" src={logo} />
      <TdsTypography fontSize={6} classes="title mb-xs"><p>{getMonthRange(i18n.language)} {year}</p></TdsTypography>
      <TdsTypography fontSize={5} classes="title mb-m"><Trans i18nKey="title"></Trans></TdsTypography>
      <div className="subtitle">
        <TdsTypography><Trans i18nKey="welcome"></Trans></TdsTypography>
        <TdsTypography classes='mb-l'><Trans i18nKey="email"></Trans><a href="mailto:tce@thalesgroup.com" className="email">tce@thalesgroup.com</a></TdsTypography>
      </div>
    </div>
  );
}