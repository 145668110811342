import React from "react";
import { TdsTypography } from "@tds/react";
import { Trans } from "react-i18next";
import useLayoutType from "../hooks/useLayoutType";
import logo from "../images/pulseTracker.svg";

export default function Footer() {
  const layoutType = useLayoutType();
  return (
    <div className="mdc-footer">
      <TdsTypography classes="text-bluegrey-400 note">
        <Trans i18nKey="note"></Trans>
      </TdsTypography>
      <TdsTypography classes="text-bluegrey-400">
        <Trans i18nKey="footer"></Trans>
      </TdsTypography>
      <div className="powerd">
      {layoutType === "mobile" && <img className="logo-pulse-mobile" src={logo} />}
      </div>

    </div>
  );
}
