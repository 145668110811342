import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        title: "Boost our Engineering - Mood & Confidence to Deliver",
        welcome: "Welcome to the Mood & Confidence to Deliver survey!",
        email: "If you have any questions, please reach out to: ",
        firstTitle: "Who are you? Please fill in what is applicable.",
        next: "Next",
        previous: "Previous",
        reset: "Reset",
        skip: "Skip",
        submit: "Submit",
        note: "A note on privacy:",
        footer:
          "This survey is anonymous. The record of your survey responses does not contain any identifying information about you, unless a specific survey question explicitly asked for it.",
        required: "Required",
        confidential: "Please do not disclose confidential information.",
        dropdown: "Choose one of the following answers.",
        placeholder: "Select your answer",
        notSatisfied: "Not satisfied",
        satisfied: "Very satisfied",
        notConfident: "Not satisfied",
        confident: "Very satisfied",
        q1: "In which country are you?",
        q2: "In which GBU are you?",
        q3: "In which BL are you?",
        ccText: "I know the name of my CC.",
        q4: "In which CC are you?",
        q5: "Please enter the name of your main project - Project name is secured and encrypted.",
        pName: "Project",
        projectNote: "Project name is secure and encrypted.",
        q5Title: "Mood & Confidence to deliver",
        q5Body:
          "Mood - Are you satisfied with your working environment (leadership, tools, learning, workload, team spirit, diversity & inclusion)?",
        q6Title: "Mood",
        q6Body:
          "Don’t hesitate to comment below if you'd like to detail your mood.",
        q7Title: "Confidence to deliver",
        q7Body:
          "Confidence to deliver - Are you confident you will meet customers expectations in terms of quality and schedule?",
        q8Title: "Confidence to deliver",
        q8Body:
          "Don’t hesitate to comment below if you'd like to detail your confidence to deliver.",
        q9: "The survey is now complete.",
        thankyou: "Thank you!",
      },
    },
    fr: {
      translations: {
        title: "Boost our Engineering - Humeur et Niveau de Confiance",
        welcome: `Bienvenue dans le questionnaire Humeur et Niveau de Confiance!`,
        email: "Pour toutes vos questions, vous pouvez écrire à: ",
        firstTitle: "Qui êtes-vous? Veuillez remplir ce qui est applicable.",
        next: "Suivant",
        reset: "Reset",
        previous: "Précédent",
        skip: "Sauter",
        submit: "Envoyer",
        note: "Remarque sur la protection de la vie privée:",
        footer:
          "Ce questionnaire est anonyme. L’enregistrement de vos réponses à ce questionnaire ne contient aucune information permettant de vous identifier, à moins que l’une des questions ne vous le demande explicitement.",
        required: "Obligatoire",
        confidential: "Merci de ne pas inclure d'informations confidentielles.",
        dropdown: "Veuillez sélectionner une des réponses ci-dessous.",
        placeholder: "Sélectionnez votre réponse",
        notSatisfied: "Pas satisfait",
        satisfied: "Très satisfait",
        notConfident: "Pas satisfait",
        confident: "Très satisfait",
        q1: "Dans quel pays êtes-vous?",
        q2: "Dans quel GBU êtes-vous?",
        q3: "Dans quel BL êtes-vous?",
        ccText: "Je connais le nom de mon CC.",
        q4: "Dans quel CC êtes-vous?",
        q5: "Veuillez saisir le nom de votre projet principal - Le nom du projet est sécurisé et crypté.",
        pName: "Projet",
        projectNote: "Le nom du projet est sécurisé et crypté.",
        q5Title: "Humeur et niveau de confiance",
        q5Body:
          "Humeur - Que pensez-vous de votre environnement de travail (leadership, outils, apprentissage, charge de travail, ambiance collective, diversité et inclusion)?",
        q6Title: "Humeur",
        q6Body:
          "Vous pouvez ajouter ci-dessous vos commentaires pour détailler votre réponse sur votre humeur.",
        q7Title: "Niveau de confiance",
        q7Body:
          "Niveau de confiance - Quel est votre niveau de confiance quant à votre capacité à tenir vos prochaines échéances clients dans les temps et avec le bon niveau de qualité?",
        q8Title: "Niveau de confiance",
        q8Body:
          "Vous pouvez ajouter ci-dessous vos commentaires pour détailler votre réponse sur votre niveau de confiance.",
        q9: "Votre réponse a été envoyée.",
        thankyou: "Merci!",
      },
    },
  },
  fallbackLng: "en",
  debug: true,

  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
