import { runWithAdal } from "react-adal";
import { authContext } from "./adal/adalConfig";

import "./common/i18n";
import './styles/base.css';

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
  require('./roots/appRoot.js');

},DO_NOT_LOGIN);
