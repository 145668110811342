import React from "react";
import Survey from "./Survey/Survey";
import Layout from "./Layout/Layout"

export default function App() {
  
  return (
    <Layout>
          <Survey/>
    </Layout>
  );
}