import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

const getRedirectUri = () => {
  const hostname = `${window.location.protocol}//${window.location.hostname}`;

  if (/localhost/.test(hostname)) {
    return `http://localhost:3000`;
  }

  return hostname;
};

/**
 * Configuration options for Authentication Context.
 * @class config
 *  @property {string} tenant - Your target tenant.
 *  @property {string} clientId - Client ID assigned to your app by Azure Active Directory.
 *  @property {string} redirectUri - Endpoint at which you expect to receive tokens.Defaults to `window.location.href`.
 *  @property {string} instance - Azure Active Directory Instance.Defaults to `https://login.microsoftonline.com/`.
 *  @property {Array} endpoints - Collection of {Endpoint-ResourceId} used for automatically attaching tokens in webApi calls.
 *  @property {Boolean} popUp - Set this to true to enable login in a popup winodow instead of a full redirect.Defaults to `false`.
 *  @property {string} localLoginUrl - Set this to redirect the user to a custom login page.
 *  @property {function} displayCall - User defined function of handling the navigation to Azure AD authorization endpoint in case of login. Defaults to 'null'.
 *  @property {string} postLogoutRedirectUri - Redirects the user to postLogoutRedirectUri after logout. Defaults is 'redirectUri'.
 *  @property {string} cacheLocation - Sets browser storage to either 'localStorage' or sessionStorage'. Defaults to 'sessionStorage'.
 *  @property {Array.<string>} anonymousEndpoints Array of keywords or URI's. Adal will not attach a token to outgoing requests that have these keywords or uri. Defaults to 'null'.
 *  @property {number} expireOffsetSeconds If the cached token is about to be expired in the expireOffsetSeconds (in seconds), Adal will renew the token instead of using the cached token. Defaults to 300 seconds.
 *  @property {string} correlationId Unique identifier used to map the request with the response. Defaults to RFC4122 version 4 guid (128 bits).
 *  @property {number} loadFrameTimeout The number of milliseconds of inactivity before a token renewal response from AAD should be considered timed out.
 */
export const adalConfig = {
  tenant: "6e603289-5e46-4e26-ac7c-03a85420a9a5",
  clientId: "08a74d1c-e038-4a04-a059-081499e68f2a",
  endpoints: {
    api: "08a74d1c-e038-4a04-a059-081499e68f2a"
  },
  cacheLocation: "localStorage",
  redirectUri: getRedirectUri(),
  postLogoutRedirectUri: `${getRedirectUri()}/logout`,
  expireOffsetSeconds: 3540,
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
