import { TdsButton, TdsTextInput, TdsTypography } from "@tds/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import EmwAutocomplete from "../../common/EmwAutocomplete";
import { hostName } from "../api";
import Footer from "../Footer";
import Header from "../Header";
import { adalApiFetch } from "../../adal/adalConfig";
import useLayoutType from "../../hooks/useLayoutType";
import { Divider } from "@mui/material";
import logo from "../../images/pulseTracker.svg";

export default function FirstPage({
  setStep,
  payload,
  setPayload,
  countries,
  setCountries,
  gbus,
  setGbus,
  bls,
  setBls,
  ccs,
  setCcs,
  setProjectName,
}) {
  const { i18n } = useTranslation();
  const layoutType = useLayoutType();

  const [selectedCountry, setSelectedCountry] = useState(
    payload.country ? payload.country : []
  );
  const [selectedGbu, setSelectedGbu] = useState(
    payload.gbuCode ? payload.gbuCode : []
  );
  const [selectedBl, setSelectedBl] = useState(
    payload.blCode ? payload.blCode : []
  );
  const [selectedCc, setSelectedCc] = useState(
    payload.ccName ? payload.ccName : []
  );
  const [lastSelected, setLastSelected] = useState(null);

  useEffect(() => {
    if (payload.country) {
      const defaultCountry = countries.find(
        (country) => country.orgValue === payload.country
      );
      if (defaultCountry) {
        setSelectedCountry(defaultCountry);
      }
    }
    if (payload.gbuCode) {
      const defaultGbu = gbus.find((gbu) => gbu.orgValue === payload.gbuCode);
      if (defaultGbu) {
        setSelectedGbu(defaultGbu);
      }
    }

    if (payload.blCode) {
      const defaultBl = bls.find((bl) => bl.orgValue === payload.blCode);
      if (defaultBl) {
        setSelectedBl(defaultBl);
      }
    }

    if (payload.ccName) {
      const defaultCc = ccs.find((cc) => cc.orgValue === payload.ccName);
      if (defaultCc) {
        setSelectedCc(defaultCc);
      }
    }
  }, []);

  useEffect(() => {
    if (
      !payload.country &&
      !payload.gbuCode &&
      !payload.blCode &&
      !payload.ccName
    ) {
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: lastSelected,
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };

        adalApiFetch(
          fetch,
          `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
          requestOptions
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.data.countries.length > 0) {
              setCountries(response.data.countries);
            }
            if (response.data.gbus.length > 0) {
              setGbus(response.data.gbus);
            }
            if (response.data.bls.length > 0) {
              setBls(response.data.bls);
            }
            if (response.data.ccs.length > 0) {
              setCcs(response.data.ccs);
            }

            if (response.data && !payload.country) {
              setSelectedCountry({ orgValue: response.data.selectedCountry });
              setPayload((prev) => ({
                ...prev,
                country:
                  response.data.selectedCountry === "I dont know" ||
                  response.data.selectedCountry === "Je ne sais pas"
                    ? null
                    : response.data.selectedCountry,
              }));
            }
            if (response.data && !payload.gbuCode) {
              setSelectedGbu({ orgValue: response.data.selectedGbu });
              setPayload((prev) => ({
                ...prev,
                gbuCode:
                  response.data.selectedGbu === "I dont know" ||
                  response.data.selectedGbu === "Je ne sais pas"
                    ? null
                    : response.data.selectedGbu,
              }));
            }
            if (response.data && !payload.blCode) {
              setSelectedBl({ orgValue: response.data.selectedBl });
              setPayload((prev) => ({
                ...prev,
                blCode:
                  response.data.selectedBl === "I dont know" ||
                  response.data.selectedBl === "Je ne sais pas"
                    ? null
                    : response.data.selectedBl,
              }));
            }
            if (response.data && !payload.ccName) {
              setSelectedCc({ orgValue: response.data.selectedCc });
              setPayload((prev) => ({
                ...prev,
                ccName: response.data.selectedCc,
              }));
            }
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("Error", e);
      }
    }

    setPayload((prev) => ({
      ...prev,
      language:
        i18n.language === "en"
          ? "English (United States)"
          : "Français (France)",
    }));
  }, [i18n.language]);

  const handleSelectCountry = async (value) => {
    if (value) {
      setSelectedCountry(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "country",
            selectedCountry: value.orgValue,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedCountry.orgValue) {
          await adalApiFetch(
            fetch,
            `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            });
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const handleSelectGbu = async (value) => {
    if (value) {
      setSelectedGbu(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "gbu",
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: value.orgValue,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedGbu.orgValue) {
          await adalApiFetch(
            fetch,
            `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            });
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const handleSelectBl = async (value) => {
    if (value) {
      setSelectedBl(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "bl",
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: value.orgValue,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedBl.orgValue) {
          await adalApiFetch(
            fetch,
            `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            });
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const handleSelectCc = async (value) => {
    if (value) {
      setSelectedCc(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "cc",
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: value.orgValue,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedCc.orgValue) {
          await adalApiFetch(
            fetch,
            `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }

              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            });
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const handleClick = () => {
    setStep((step) => {
      return step + 1;
    });
  };

  const handleClickReset = () => {
    setSelectedCountry([]);
    setSelectedGbu([]);
    setSelectedBl([]);
    setSelectedCc([]);
    setProjectName("");
    try {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };

      adalApiFetch(
        fetch,
        `${hostName}/api/v1/mcd/app/getOrganizationStructure`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          setCountries(response.data.countries);
          setGbus(response.data.gbus);
          setBls(response.data.bls);
          setCcs(response.data.ccs);
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log("Error", e);
    }
    setPayload((prev) => ({
      ...prev,
      country: null,
      gbuCode: null,
      blCode: null,
      ccName: null,
      pName: null,
      moodSatisfaction: null,
      moodComment: null,
      confindenceToDeliverSatisfaction: null,
      confindenceToDeliverComment: null,
      key: null,
    }));
  };

  return (
    <>
      <Header />
      <div className="pulse">
        <div className="page-title">
          <TdsTypography fontSize={4}>
            <Trans i18nKey="firstTitle"></Trans>
          </TdsTypography>
        </div>
        {layoutType === "desktop" && <img className="logo-pulse" src={logo} />}
      </div>
      <div className={`dropdowns`}>
        <div className={layoutType === "mobile" ? "dropdown" : "dropdown left"}>
          {layoutType === "desktop" && (
            <TdsTypography classes="mb-m text-bluegrey-900" fontSize={6}>
              <Trans i18nKey="ccText"></Trans>
            </TdsTypography>
          )}
          <TdsTypography fontSize="6" classes="question text-bluegrey-600">
            <p>
              <Trans i18nKey="q4"></Trans>
            </p>
          </TdsTypography>
          <TdsTypography
            fontSize={7}
            classes="dropdown-text mb-xs text-bluegrey-600"
          >
            <Trans i18nKey="dropdown"></Trans>
          </TdsTypography>
          <EmwAutocomplete
            id="dropdown-cc"
            placeholder={`${
              i18n.language === "en"
                ? "Select your answer"
                : "Sélectionnez votre réponse"
            }`}
            options={ccs}
            value={selectedCc || ""}
            callback={handleSelectCc}
            selectedCc={selectedCc}
          />
        </div>
        {layoutType === "mobile" ? (
          ""
        ) : (
          <Divider className="centerd" orientation="vertical" flexItem />
        )}

        <div className={layoutType === "mobile" ? "" : "right-block"}>
          <div className="dropdown right">
            <TdsTypography fontSize="6" classes="question text-bluegrey-600">
              <p>
                <Trans i18nKey="q1"></Trans>
              </p>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="dropdown-text mb-xs text-bluegrey-600"
            >
              <Trans i18nKey="dropdown"></Trans>
            </TdsTypography>
            <EmwAutocomplete
              id="dropdown-country"
              placeholder={`${
                i18n.language === "en"
                  ? "Select your answer"
                  : "Sélectionnez votre réponse"
              }`}
              options={countries || ""}
              value={selectedCountry || ""}
              callback={handleSelectCountry}
              selectedCountry={selectedCountry}
            />
          </div>
          <div className="dropdown right">
            <TdsTypography fontSize="6" classes="question text-bluegrey-600">
              <p>
                <Trans i18nKey="q2"></Trans>
              </p>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="dropdown-text mb-xs text-bluegrey-600"
            >
              <Trans i18nKey="dropdown"></Trans>
            </TdsTypography>
            <EmwAutocomplete
              id="dropdown-gbu"
              placeholder={`${
                i18n.language === "en"
                  ? "Select your answer"
                  : "Sélectionnez votre réponse"
              }`}
              options={gbus}
              value={selectedGbu || ""}
              callback={handleSelectGbu}
              selectedGbu={selectedGbu}
            />
          </div>
          <div className="dropdown right">
            <TdsTypography fontSize="6" classes="question text-bluegrey-600">
              <p>
                <Trans i18nKey="q3"></Trans>
              </p>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="dropdown-text mb-xs text-bluegrey-600"
            >
              <Trans i18nKey="dropdown"></Trans>
            </TdsTypography>
            <EmwAutocomplete
              id="dropdown-bl"
              placeholder={`${
                i18n.language === "en"
                  ? "Select your answer"
                  : "Sélectionnez votre réponse"
              }`}
              options={bls}
              value={selectedBl || ""}
              callback={handleSelectBl}
              selectedBl={selectedBl}
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <TdsButton
          variant="outline"
          color="primary"
          classes="btn-previous"
          onClick={handleClickReset}
        >
          <Trans i18nKey="reset"></Trans>
        </TdsButton>
        <TdsButton
          id="btnNext"
          variant="filled"
          color="primary"
          classes="btn-next"
          onClick={handleClick}
        >
          <Trans i18nKey="next"></Trans>
        </TdsButton>
      </div>
      <Footer />
    </>
  );
}
